<template>
  <main class="columns is-desktop">
    <div class="column is-half mx-auto is-flex is-align-items-center">
      <div
        class="registerForm m-auto is-fullwidth is-flex is-flex-direction-column is-justify-content-space-evenly px-3 py-4"
      >
        <router-link to="/login">
          <img src="/img/logo-skribix.png" alt="Logo Skribix" class="mb-2" />
        </router-link>

        <h2 v-if="canSubscribe" class="pb-2">
          Rejoignez la communauté Skribix
        </h2>

        <div class="">
          <p v-if="!canSubscribe" class="has-text-pink is-size-4">
            Skribix n'accepte plus de rédacteurs pour le moment. Reviens dans
            quelques temps.
          </p>

          <div
            class="is-flex is-justify-content-space-between is-align-items-center mt-4"
          >
            <router-link
              to="/login"
              class="btn has-text-blue is-outlined is-underlined"
            >
              Vous avez déjà un compte ?
            </router-link>
          </div>
        </div>

        <CandidateRegistrationForm v-if="canSubscribe" />
      </div>
    </div>
  </main>
</template>
<script>
import CandidateRegistrationForm from "@/components/Model/Guest/CandidateRegistrationForm.vue";

export default {
  name: "CandidateRegistrationView",
  title: "title.auth.client_registration",
  components: { CandidateRegistrationForm },
  data() {
    return {
      canSubscribe: process.env.VUE_APP_CANDIDATE_SUBSCRIPTION !== "false",
    };
  },
  mounted() {
    if (!this.canSubscribe && this.$route.query.force !== undefined) {
      this.canSubscribe =
        btoa(prompt("Entrez le mot de passe pour vous inscrire", "")) ===
        "aEqjKVBDOE84MEx1";
    }
  },
};
</script>
<style scoped>
.registerForm .image {
  width: 20vh;
  height: 20vh;
}

@media (min-width: 768px) {
  .registerForm {
    min-height: 70%;
  }
  .whiteBackground {
    width: 100%;
    min-height: max-content;
  }
}
</style>
