<template>
  <component
    v-bind="$attrs"
    :is="tag"
    :class="`button is-${size} is-inverted has-text-${buttonColor} has-border-${buttonColor} has-hover-bottom is-flex is-justify-content-center`"
    :disabled="disabled || loading"
    @click="clickAction"
  >
    <span v-if="!iconRight" class="is-flex is-align-items-center">
      <component
        :is="activeIcon"
        :class="{
          'mr-2': text,
          'is-spinning': loading,
        }"
      ></component>
      {{ text }}
    </span>
    <span v-else class="is-flex is-align-items-center">
      {{ text }}
      <component
        :is="activeIcon"
        :class="{ 'ml-2': text, 'is-spinning': loading }"
      ></component>
    </span>
  </component>
</template>
<script>
export default {
  name: "ButtonIcon",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    icon: {},
    iconRight: {},
    color: {},
    text: {},
    disabled: {},
    size: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonColor() {
      return !this.disabled || this.disabled === false
        ? this.color
        : "grey-light";
    },
    activeIcon() {
      return this.loading ? "IconLoader" : this.icon;
    },
  },
  methods: {
    clickAction(e) {
      if (this.tag !== "router-link") {
        e.preventDefault();
      }

      this.$emit("click-button");
    },
  },
};
</script>
<style scoped>
a.button {
  width: max-content;
}
</style>
